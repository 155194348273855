import i18n from '@/i18n';

const { t } = i18n.global;

export const languageOptions = [
    { name: 'nl', display_name: 'Nederlands' },
    { name: 'fr', display_name: 'Français' },
    { name: 'en', display_name: 'English' },
];

export const genderOptions = [
    { name: 'male', display_name: t('male', 1) },
    { name: 'female', display_name: t('female', 1) },
    { name: 'other', display_name: t('other', 1) },
];
