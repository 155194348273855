<template>
    <PageLoader>
        <Header :title="$tc('create_company', 1)" :backLinkText="$tc('back_to_company', 2)" :backLinkURL="{ name: 'companies' }" />
        <section class="mt-10">
            <form @submit.prevent="createCompany" @keydown.enter="$event.preventDefault()" class="divide-y divide-fibonaki-border">
                <fieldset class="pb-6">
                    <header>
                        <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('general', 1) }}</h3>
                    </header>

                    <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                        <div class="col-span-12">
                            <Input :label="$tc('enterprise_number', 1)" type="text" id="enterprise_number" v-model="company.enterprise_number" :error="validationErrors.enterprise_number" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('business_name', 1)" type="text" id="companyName" v-model="company.business_name" :error="validationErrors.business_name" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('legal_entity_type', 1)" type="text" id="companyType" v-model="company.legal_entity_type" :error="validationErrors.legal_entity_type" />
                        </div>
                        <div class="col-span-3">
                            <Input :label="$tc('phone', 1)" type="text" id="phone" v-model="company.phone" :error="validationErrors.phone" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('email_address', 1)" type="text" id="email" v-model="company.email" :error="validationErrors.email" />
                        </div>
                    </div>
                </fieldset>

                <fieldset class="py-6">
                    <header>
                        <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('physical_address', 1) }}</h3>
                    </header>

                    <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                        <div class="col-span-8">
                            <Input :label="$tc('street', 1)" type="text" id="street" v-model="company.physical_address.street" :error="validationErrors['physical_address.street']" />
                        </div>
                        <div class="col-span-2">
                            <Input :label="$tc('number', 1)" type="text" id="number" v-model="company.physical_address.number" :error="validationErrors['physical_address.number']" />
                        </div>
                        <div class="col-span-2">
                            <Input :label="$tc('addition', 1)" optional type="text" id="addition" v-model="company.physical_address.addition" :error="validationErrors['physical_address.addition']" />
                        </div>
                        <div class="col-span-8">
                            <SelectWithSearch :label="$tc('city', 1)" id="billing_address_postal_code_id" v-model="company.physical_address.postal_code_id" :options="cityOptions" displayProperty="display_name" valueProperty="id" :minLengthForDropdown="3" :error="validationErrors['physical_address.postal_code_id']" />
                        </div>
                        <div class="col-span-4">
                            <Input :label="$tc('country', 1)" type="text" id="country" v-model="company.physical_address.country" :error="validationErrors['physical_address.country']" />
                        </div>
                    </div>
                </fieldset>

                <fieldset class="py-6">
                    <header>
                        <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('billing_address', 1) }}</h3>
                    </header>

                    <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                        <div class="col-span-12">
                            <Checkbox :label="$tc('billing_same_as_physical', 1)" v-model="company.same_billing_address" :error="validationErrors.same_billing_address" />
                        </div>

                        <template v-if="!company.same_billing_address">
                            <div class="col-span-8">
                                <Input :label="$tc('street', 1)" type="text" id="street" v-model="company.billing_address.street" :error="validationErrors['billing_address.street']" />
                            </div>
                            <div class="col-span-2">
                                <Input :label="$tc('number', 1)" type="text" id="number" v-model="company.billing_address.number" :error="validationErrors['billing_address.number']" />
                            </div>
                            <div class="col-span-2">
                                <Input :label="$tc('addition', 1)" type="text" id="addition" v-model="company.billing_address.addition" :error="validationErrors['billing_address.addition']" />
                            </div>
                            <div class="col-span-8">
                                <SelectWithSearch :label="$tc('city', 1)" id="billing_address_postal_code_id" v-model="company.billing_address.postal_code_id" :options="cityOptions" displayProperty="display_name" valueProperty="id" :minLengthForDropdown="3" :error="validationErrors['billing_address.postal_code_id']" />
                            </div>
                            <div class="col-span-4">
                                <Input :label="$tc('country', 1)" type="text" id="country" v-model="company.billing_address.country" :error="validationErrors['billing_address.country']" />
                            </div>
                        </template>
                    </div>
                </fieldset>

                <fieldset class="py-6">
                    <div class="flex justify-between mb-3">
                        <header>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">{{ $tc('contact', company.contacts.length) }}</h3>
                        </header>
                        <button type="button" class="text-sm leading-6 font-medium text-blue-500 flex items-center" @click="addContact">{{ $tc('add', 1) }} {{ $tc('contact', 1).toLowerCase() }}</button>
                    </div>

                    <section class="space-y-6">
                        <div v-for="(contact, contactIdx) in company.contacts" :key="contactIdx">
                            <h4 v-show="company.contacts.length > 1" class="mb-3 text-sm leading-6 font-medium text-gray-500">
                                {{ $tc('contact', 1) }} {{ contactIdx + 1 }} <span @click="deleteContact(contactIdx)" class="text-blue-500 cursor-pointer select-none">({{ $tc('delete', 1) }})</span>
                            </h4>

                            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                                <div class="col-span-12">
                                    <RadioButtonGroup :label="$tc('gender', 1)" :options="genderOptions" displayProperty="display_name" valueProperty="name" v-model="contact.gender" :error="validationErrors[`contacts[${contactIdx}].gender`]" />
                                </div>
                                <div class="col-span-6">
                                    <Input :label="$tc('first_name', 1)" type="text" id="first_name" v-model="contact.first_name" :error="validationErrors[`contacts[${contactIdx}].first_name`]" />
                                </div>
                                <div class="col-span-6">
                                    <Input :label="$tc('last_name', 1)" type="text" id="last_name" v-model="contact.last_name" :error="validationErrors[`contacts[${contactIdx}].last_name`]" />
                                </div>
                                <div class="col-span-3">
                                    <Input :label="$tc('phone', 1)" type="text" id="phone" v-model="contact.phone" :error="validationErrors[`contacts[${contactIdx}].phone`]" />
                                </div>
                                <div class="col-span-6">
                                    <Input :label="$tc('email_address', 1)" type="text" id="email" v-model="contact.email" :error="validationErrors[`contacts[${contactIdx}].email`]" />
                                </div>
                                <div class="col-span-3">
                                    <Input :label="$tc('date_of_birth', 1)" type="date" id="date_of_birth" v-model="contact.date_of_birth" :error="validationErrors[`contacts[${contactIdx}].date_of_birth`]" />
                                </div>
                                <div class="col-span-9">
                                    <Input :label="$tc('function', 1)" type="text" id="function" v-model="contact.function" :error="validationErrors[`contacts[${contactIdx}].function`]" />
                                </div>
                                <div class="col-span-3">
                                    <Select :label="$tc('language', 1)" id="languageOfContact" :options="languageOptions" displayProperty="display_name" valueProperty="name" v-model="contact.language" :error="validationErrors[`contacts[${contactIdx}].language`]" />
                                </div>
                            </div>
                        </div>
                    </section>
                </fieldset>

                <fieldset class="pt-6">
                    <SubmitButton :label="$tc('create_company', 1)" submittingLabel="Creating company..." />
                </fieldset>
            </form>
        </section>
    </PageLoader>
</template>

<script>
    import AddressService from '@/services/AddressService';
    import { CreateCompanyValidationSchema } from '@/utils/validators';
    import { languageOptions, genderOptions } from '@/data/formData';

    export default {
        mounted() {
            this.loadData();
        },
        data() {
            return {
                company: {
                    same_billing_address: true,
                    enterprise_number: '01233456789',
                    business_name: 'Test company',
                    legal_entity_type: 'BV',
                    phone: '0123456789',
                    email: 'info@company.com',
                    physical_address: {
                        street: 'Test street',
                        number: '12',
                        postal_code_id: 2200,
                        country: 'Belgie',
                    },
                    billing_address: {},
                    language: 'nl',
                    contacts: [
                        {
                            function: '',
                            first_name: '',
                            last_name: 'Rigolle',
                            phone: '0123456789',
                            gender: 'male',
                            email: 'stijn.rigolle@enbro.be',
                            language: 'ff',
                            date_of_birth: '1980-12-20',
                        },
                    ],
                },
                cityOptions: [],
                validationErrors: {},
                languageOptions,
                genderOptions,
            };
        },
        methods: {
            addContact() {
                this.company.contacts.unshift({
                    function: '',
                    first_name: '',
                    last_name: '',
                    phone: '',
                    gender: 'male',
                    email: '',
                    language: 'nl',
                    date_of_birth: '',
                });
            },
            deleteContact(index) {
                if (this.company.contacts.length > 1) {
                    this.company.contacts.splice(index, 1);
                }
            },

            async createCompany() {
                try {
                    this.validationErrors = {};
                    await CreateCompanyValidationSchema.validate(this.company, { abortEarly: false });
                } catch (err) {
                    err.inner.forEach((error) => {
                        this.validationErrors = { ...this.validationErrors, [error.path]: error.message };
                    });
                }
            },

            async loadData() {
                setTimeout(async () => {
                    const citiesResponse = await AddressService.getCities();

                    this.cityOptions = citiesResponse.data.cities.map((city) => {
                        return {
                            id: city.id,
                            display_name: `${city.sub_city} (${city.zip})`,
                        };
                    });
                }, 3000);
            },
        },
    };
</script>
